html,
body {
  background-color: var(--color-light-gray);
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  scroll-behavior: smooth;

  /* colors */
  --color-white: #fff;
  --color-light-gray: #e4e4e4;
  --color-dark-gray: #f5f3f544;
  --color-black: #282c34;

  /* custom colors */
  --color-blue: #29298a;
  --color-sky-blue: #63cae3;

  /* util styling variables */
  --border-radius: 20px;
  --box-shadow: 0px 3px 3px 2px #3d3d3d80;

  button,
  input[type="submit"] {
    cursor: pointer;
    padding: 15px;
    border-radius: 5px;
    background-color: var(--color-sky-blue);
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    color: white;
    font-size: 1.4rem;
  }

  button:hover,
  input[type="submit"]:hover {
    background-color: #3ed7fd;
  }
}

button {
  font-size: 1.4rem;
  svg {
    transform: scale(2);
    margin: 5px 10px;
  }
}

.logo-container {
  z-index: 9999;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding-bottom: 8px;
}

.sidebar {
  z-index: 0;
  height: 100%;
  width: 15vw;
  position: fixed;
  padding-left: 20px;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom right, var(--color-blue) 60%, #4646c2);
  overflow-x: hidden;
}

.sidebar a {
  display: flex;
  color: white;
  padding-bottom: 6px;
  text-decoration: none;
  text-align: left;
  font-weight: 500;
  font-size: 1.4rem;
  margin: 5px 10px;
}

.sidebar a:hover {
  font-weight: bold;
}

.sidebar a,
.sidebar button {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  text-align: left;
  font-weight: 500;
}

.sidebar button {
  margin-top: 20px;
}

.sidebar a:hover {
  font-weight: bold;
}

.footer-items {
  position: absolute;
  font-size: small;
  margin-bottom: 5vh;
  bottom: 0;
}

.footer-items ul,
p,
span {
  list-style-type: none;
  text-align: left;
  padding: 0;
  margin: 0;
}

.footer-items p {
  font-size: medium;
  font-weight: bold;
  padding-bottom: 10px;
}

.footer-items span {
  font-style: italic;
}

.footer-items ul li {
  color: white;
  margin-bottom: 5px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--color-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

.App-main {
  background-color: var(--color-light-gray);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

.App-popup {
  width: 100vw;
  height: 100vh;
  background-color: rgba(00, 00, 00, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: ease-in-out 0.2s;
}

.App-link {
  color: #61dafb;
}
.App-graph-container {
  background: var(--color-white);
  margin-bottom: 5vh;
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  border-radius: 10px;
  box-shadow: 0px 3px 3px 2px #3d3d3d80;
}

form,
label {
  font-size: medium;
}

form h3 {
  font-size: large;
}

input[type="text"],
input[type="number"],
input[type="datetime-local"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
